<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-4 container">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/otherss/PASS THRU TYPE AUTO LOADER.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h5 class="font-size-16 mb-3">
                    <strong> Product description: </strong>
                  </h5>
                  <h6>
PASS THRU TYPE AUTO LOADER   </h6>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br /><br /><br />

            <div class="mt-4 container">
              <h5 class="font-size-14">Related Products :</h5>
              <div class="container fluid">
                <div class="row" style="margin-top: 50px">
                 
                  
                  <!-- end col -->
                  <div class="col-md-6 col-xl-2">
                    <!-- Simple card -->
                    <router-link
            to="/production_equipement/board_handling_system/otherss/manual_insertion_conveyor"
                    >
                      <div class="card" style="width: 160px; height: 200px">
                        <img
                          style="max-height: 80%; max-width: 100%; margin: auto"
                          class="card-img-top img-fluid"
                          src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/otherss/Manual Insertion Conveyor.jpg"
                          alt="Card image cap"
                        />
                        <div class="card-body">
                          <h4 style="text-align: center" class="card-title">
                            Manual Insertion Conveyor
                          </h4>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- end col -->
                 <div class="col-md-6 col-xl-2">
                    <!-- Simple card -->
                    <router-link
                      to="/production_equipement/board_handling_system/otherss/ng_reject_conveyor"
                    >
                      <div class="card" style="width: 160px; height: 200px">
                        <img
                          style="max-height: 80%; max-width: 100%; margin: auto"
                          class="card-img-top img-fluid"
                          src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/otherss/NG Reject Conveyor.jpg"
                          alt="Card image cap"
                        />
                        <div class="card-body">
                          <h4 style="text-align: center" class="card-title">
                            NG Reject Conveyor
                          </h4>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- end col -->
                  <div class="col-md-6 col-xl-2">
                    <!-- Simple card -->
                    <router-link
            to="/production_equipement/board_handling_system/otherss/national_prorack"
                    >
                      <div class="card" style="width: 160px; height: 200px">
                        <img
                          style="max-height: 80%; max-width: 100%; margin: auto"
                          class="card-img-top img-fluid"
                          src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/otherss/NATIONAL PRORACK.jpg"
                          alt="Card image cap"
                        />

                        <div class="card-body">
                          <h4 style="text-align: center" class="card-title">
                            NATIONAL PRORACK
                          </h4>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- end col -->
                  <div class="col-md-6 col-xl-2">
                    <!-- Simple card -->
                    <router-link
            to="/production_equipement/board_handling_system/otherss/nikko_rack"
                    >
                      <div class="card" style="width: 160px; height: 200px">
                        <img
                          style="max-height: 80%; max-width: 100%; margin: auto"
                          class="card-img-top img-fluid"
                          src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/otherss/NIKKO RACK.jpg"
                          alt="Card image cap"
                        />

                        <div class="card-body">
                          <h4 style="text-align: center" class="card-title">
                            NIKKO RACK
                          </h4>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- end col -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from ".././../../../../components/Header.vue";

import Footer from ".././../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>